import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  UnorderedList,
  ListItem,
  Link
} from "@chakra-ui/react";

function TermsOfUseModal({ isOpen, onClose }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Use</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxH="80vh">
            {/* Introduction */}
            <Text mb={4}>
              Welcome to Spatium Tech (www.spatiumtech.com). These terms of use
              ("Terms") govern your access to and use of our Website and its
              related services (collectively, the "Services"). By accessing or
              using our Services, you confirm your agreement to be bound by
              these Terms. If you do not agree to these Terms, do not use our
              Services.
            </Text>

            {/* Intellectual Property Rights */}
            <Text mb={4}>
              <strong>Intellectual Property Rights:</strong> All content
              published and made available on our Website, including texts,
              graphics, logos, icons, images, audio clips, digital downloads,
              and software, is the property of Spatium Tech and is protected by
              intellectual property laws. Unless expressly stated otherwise,
              your use of our Services does not grant you ownership of any
              content, code, data, or materials you may access on our Website.
            </Text>

            {/* Your Responsibilities */}
            <Text mb={4}>
              <strong>Your Responsibilities:</strong> You agree to use our
              Website and Services only for lawful purposes and in accordance
              with these Terms. You agree not to use our Services:
              <UnorderedList ml={4} mt={2}>
                <ListItem>
                  In any way that violates any applicable national or
                  international law or regulation.
                </ListItem>
                <ListItem>
                  For exploitative, harmful, or fraudulent purposes.
                </ListItem>
                <ListItem>
                  To impersonate or attempt to impersonate Spatium Tech, a
                  Spatium Tech's employee, another user, or any other person or
                  entity.
                </ListItem>
                <ListItem>
                  To engage in any conduct that restricts or inhibits anyone’s
                  use or enjoyment of the Website, or which, as determined by
                  us, may harm [Your Company Name] or users of the Website or
                  expose them to liability.
                </ListItem>
              </UnorderedList>
            </Text>

            {/* Website Services */}
            <Text mb={4}>
              <strong>Website Services:</strong> Our Website provides learning
              and reading content. We reserve the right to refuse service to
              anyone for any reason at any time. You understand that your
              content (not including credit card information), may be
              transferred unencrypted and involve transmissions over various
              networks.
            </Text>

            {/* Limitation of Liability */}
            <Text mb={4}>
              <strong>Limitation of Liability:</strong> To the fullest extent
              permitted by applicable law, Spatium Tech will not be liable for
              any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses resulting from:
              <UnorderedList>
                <ListItem>
                  Your access to, use of, or inability to access or use the
                  Services.
                </ListItem>
                <ListItem>
                  Any conduct or content of any third party on the Services.
                </ListItem>
              </UnorderedList>
            </Text>

            {/* Governing Law */}
            <Text mb={4}>
              <strong>Governing Law:</strong> These Terms shall be governed by
              and defined following the laws of Rio Grande do Sul/Brazil.
              Spatium Tech and yourself irrevocably consent that the courts of
              Rio Grande do Sul/Brazil shall have exclusive jurisdiction to
              resolve any dispute which may arise in connection with these
              terms.
            </Text>

            {/* Changes to Terms */}
            <Text mb={4}>
              <strong>Changes to Terms:</strong> We reserve the right, at our
              sole discretion, to modify or replace these Terms at any time. If
              a revision is material, we will make reasonable efforts to provide
              at least 30 days' notice prior to any new terms taking effect.
              What constitutes a material change will be determined at our sole
              discretion. By continuing to access or use our Services after
              those revisions become effective, you agree to be bound by the
              revised terms. If you do not agree to the new terms, you are no
              longer authorized to use the Services. For questions or concerns
              about these Terms, please contact us at{" "}
              <Link
                href="mailto:info@spatiumtech.com"
                color="teal.500"
                isExternal
              >
                info@spatiumtech.com
              </Link>
              .
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button bg="#18D9D9" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TermsOfUseModal;
