import React, { createContext, useState, useEffect } from 'react';
import { getBlogs, getAuthors, getTutorials } from './FetchData'; // Adjust the import path as needed

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [blogData, setBlogData] = useState([]);
  const [tutorialData, setTutorialData] = useState([]);
  const [authorData, setAuthorData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [blogs, authors, tutorials] = await Promise.all([getBlogs(), getAuthors(), getTutorials()]);
        setBlogData(blogs);
        setTutorialData(tutorials);
        setAuthorData(authors);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ blogData, authorData, tutorialData }}>
      {children}
    </DataContext.Provider>
  );
};