import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Text,
  Divider,
  Container,
  Flex,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Image,
  Badge,
  VStack,
  Heading,
  useClipboard,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FaGithub, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { ChevronRightIcon, CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // Example theme
// Import additional languages if needed
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-java";
import "prismjs/components/prism-python";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-shell-session";
import "../../../styles/AbapCustomStyle";
import "../../../styles/PowershellCustom";
import copy from "copy-to-clipboard";
import { DataContext } from "../../../services/DataContext";

const endLogo = "/images/1-icon.png";

// A component for rendering the breadcrumb
const TutorialBreadcrumb = ({ title }) => (
  <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
    <BreadcrumbItem>
      <BreadcrumbLink as={Link} to="/learning">
        Learning
      </BreadcrumbLink>
    </BreadcrumbItem>
    <BreadcrumbItem isCurrentPage>
      <BreadcrumbLink>{title}</BreadcrumbLink>
    </BreadcrumbItem>
  </Breadcrumb>
);

const ContentBlock = ({ block }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [block]); // Rerun highlighting when the block changes

  const codeRef = useRef(null);
  const { hasCopied, onCopy } = useClipboard(
    codeRef.current?.textContent || ""
  );

  // Function to copy the code to the clipboard
  const handleCopy = () => {
    if (codeRef.current) {
      copy(codeRef.current.textContent);
      onCopy();
    }
  };

  switch (block.type) {
    case "text":
      if (block.type === "text") {
        // Check if the content should be rendered as HTML
        if (block.textType === "normal" && block.content.includes("<ul>")) {
          return (
            <Box
              mb="20px"
              ml="30px"
              dangerouslySetInnerHTML={{ __html: block.content }}
            />
          );
        } else if (block.textType === "subtitle") {
          return (
            <Text fontSize="xl" fontWeight="bold" mb="20px">
              {block.content}
            </Text>
          );
        } else {
          return (
            <Text fontSize="md" mb="20px">
              {block.content}
            </Text>
          );
        }
      }
      break;
    case "code": {
      const prismLanguageClass = `language-${block.language}`;
      const isShell = block.language === 'shell';
      return (
        <Box position="relative" mb="20px">
          <IconButton
            aria-label="Copy code"
            icon={<CopyIcon />}
            size="sm"
            position="absolute"
            top="1"
            right="1"
            zIndex="1"
            onClick={handleCopy}
          />
          <Tooltip
            label={hasCopied ? "Copied!" : "Copy"}
            closeOnClick={false}
            isOpen={hasCopied}
          >
            <Box
              as="pre"
              ref={codeRef}
              p="4"
              overflowX="auto"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="md"
              position="relative"
              style={isShell ? { backgroundColor: 'black', color: 'white' } : {}}
            >
              <code className={prismLanguageClass}>{block.content}</code>
            </Box>
          </Tooltip>
        </Box>
      );
    }

    case "image":
      return (
        <Image
          src={block.src}
          alt={block.alt || "Image"}
          width="100%"
          height="auto"
          maxH="700px"
          objectFit="cover"
          mb="20px"
        />
      );
    case "link":
      return (
        <Box mb="20px">
          <a
            href={block.linkUrl} // Use 'href' for external links
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "blue.500", // Set the initial color
              fontWeight: "bold", // Make the text bold
              textDecoration: "none", // Remove underline by default
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.textDecoration = "underline"; // Underline on hover
              currentTarget.style.color = "blue.700"; // Change color on hover
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.textDecoration = "none"; // Remove underline when not hovering
              currentTarget.style.color = "blue.500"; // Revert color when not hovering
            }}
            onFocus={({ currentTarget }) => {
              currentTarget.style.textDecoration = "underline"; // Underline on focus for accessibility
              currentTarget.style.color = "blue.700"; // Change color on focus
            }}
            onBlur={({ currentTarget }) => {
              currentTarget.style.textDecoration = "none"; // Remove underline when focus is lost
              currentTarget.style.color = "blue.500"; // Revert color when focus is lost
            }}
          >
            <ExternalLinkIcon mr="2" /> {/* Icon before the text */}
            {block.linkText}
          </a>
        </Box>
      );

    default:
      return null;
  }
};

ContentBlock.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
};

const TutorialFooter = ({ tutorial }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="full"
      mt={4}
      px={4}
    >
      <Popover>
        <PopoverTrigger>
          <Box cursor="pointer">
            <Flex alignItems="center">
              <Image
                borderRadius="full"
                boxSize="40px"
                src={tutorial.author.authorAvatar}
                alt="Author"
              />
              <Text ml={2}>{tutorial.author.author}</Text>
            </Flex>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{tutorial.author.author}</PopoverHeader>
          <PopoverBody>
            <Text mb={4}>{tutorial.author.authorTitle}</Text>
            <HStack spacing={4}>
              {tutorial.author.authorInstagram && (
                <Link
                  to={tutorial.author.authorInstagram}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaInstagram} w={5} h={5} />
                </Link>
              )}
              {tutorial.author.authorYoutube && (
                <Link
                  to={tutorial.author.authorYoutube}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaYoutube} w={5} h={5} />
                </Link>
              )}
              {tutorial.author.authorLinkedin && (
                <Link
                  to={tutorial.author.authorLinkedin}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaLinkedin} w={5} h={5} />
                </Link>
              )}
              {tutorial.author.authorGithub && (
                <Link
                  to={tutorial.author.authorGithub}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaGithub} w={5} h={5} />
                </Link>
              )}
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Text>
        {new Date(
          new Date(tutorial.date).getTime() +
            new Date(tutorial.date).getTimezoneOffset() * 60000
        ).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </Text>
    </Flex>
  );
};

const TutorialPage = () => {
  const { path } = useParams();
  const { tutorialData, authorData } = useContext(DataContext);

  const yoututeIconBgColor = useColorModeValue(
    "youtubeIcon.light.backgroundColor",
    "youtubeIcon.dark.backgroundColor"
  );

  const tutorial = useMemo(() => {
    const foundTutorial = tutorialData.find((t) => t.id === path);
    if (foundTutorial && authorData) {
      // Find the author with the matching ID
      const author =
        authorData.find((a) => a.id === foundTutorial.authorID) || {};
      return { ...foundTutorial, author }; // Combine blog with its author
    }
    return null;
  }, [path, tutorialData, authorData]);

  if (!tutorial) {
    return (
      <Box p="4">
        <Text>No tutorial found.</Text>
      </Box>
    );
  }

  return (
    <Container
      maxW={{ base: "100%", sm: "600px", md: "600px", lg: "600px" }}
      p="2rem"
    >
      <TutorialBreadcrumb title={tutorial.shortTitle} />
      <VStack spacing="4" align="left" textAlign="left">
        <Heading as="h1" mt="10px" mb="0">
          {tutorial.title}
        </Heading>
        <Divider />
        <HStack
          justifyContent="center"
          spacing={4}
          mt={tutorial.tVideo || tutorial.tCode ? "4" : "-20px"}
          mb={4}
          width="100%"
        >
          {tutorial.tVideo && (
            <Badge
              color="#18D9D9"
              display="flex"
              alignItems="center"
              variant="outline"
            >
              <Icon
                as={FaYoutube}
                w={4}
                h={4}
                mr={2}
                color={yoututeIconBgColor}
              />
              <Box as="span" lineHeight="inherit">
                Video Tutorial Available
              </Box>
            </Badge>
          )}

          {tutorial.tCode && (
            <Badge
              color="#18D9D9"
              display="flex"
              alignItems="center"
              variant="outline"
            >
              <Icon
                as={FaGithub}
                w={4}
                h={4}
                mr={2}
                color={yoututeIconBgColor}
              />
              <Box as="span" lineHeight="inherit">
                Code Repository Available
              </Box>
            </Badge>
          )}
        </HStack>
        <Box w="full" overflow="hidden">
          {tutorial.content.map((block, index) => (
            <ContentBlock key={index} block={block} />
          ))}
        </Box>
        <HStack spacing={8} justifyContent="center" width="100%" mt={4}>
          {tutorial.tVideo && (
            <Tooltip
              hasArrow
              label="Watch the video tutorial"
              aria-label="Tooltip"
            >
              <Link to={tutorial.videoURL} isExternal target="_blank">
                <Icon as={FaYoutube} w={10} h={10} color={yoututeIconBgColor} />
              </Link>
            </Tooltip>
          )}
          {tutorial.tCode && (
            <Tooltip
              hasArrow
              label="View the code repository"
              aria-label="Tooltip"
            >
              <Link to={tutorial.codeURL} isExternal target="_blank">
                <Icon as={FaGithub} w={10} h={10} color={yoututeIconBgColor} />
              </Link>
            </Tooltip>
          )}
        </HStack>
        <TutorialFooter tutorial={tutorial} />
        <Flex justifyContent="center" width="full" my="20px">
          <Image
            src={endLogo}
            alt="Company Logo"
            w="100px"
            h="auto"
            objectFit="contain"
            marginTop="10px"
          />
        </Flex>
      </VStack>
    </Container>
  );
};

export default TutorialPage;
