import emailjs from "@emailjs/browser";

const contactUsEmail = (serviceId, subscriptionTemplateId, formRef, userId) => {
  const form = formRef.current ? formRef.current : formRef;

  // Check if any required form field is empty
  for (const element of form.elements) {
    if (
      element.tagName === "INPUT" &&
      element.type !== "submit" &&
      !element.value
    ) {
      // Reject with an Error object
      return Promise.reject(new Error("Please fill in all required fields."));
    }
  }

  return emailjs.sendForm(serviceId, subscriptionTemplateId, form, userId);
};

export { contactUsEmail };
