import Prism from 'prismjs';

Prism.languages.abap = {
  'comment': /".*/,
  'string': {
    pattern: /(`|')(?:\\.|(?!\1)[^\\\r\n])*\1/,
    greedy: true
  },
  'keyword': /\b(?:IF|ENDIF|WRITE|CONSTANTS|DATA|BEGIN|END|CLASS|METHOD|ENDMETHOD|PUBLIC|PRIVATE|PROTECTED|FUNCTION|CALL|SELECT|FROM|WHERE|INNER|LEFT|JOIN|ON|ORDER|BY|GROUP|HAVING|INTO|LIKE|IS|NULL|NOT|AND|OR|CASE|WHEN|THEN|ELSE|ENDCASE|LOOP|ENDLOOP|AT|ON|EXIT|CHECK|CONTINUE|DO|ENDDO|FORM|ENDFORM|TRY|CATCH|CLEANUP|ENDTRY|RAISE|EVENT|EXPORT|IMPORT|TYPES|TYPE|STRUCTURE|TYPE-POOL|TYPE-POOLS|INTERFACE|INTERFACES|ALIASES|DEFAULT|FOR|ALL|ENTRIES|IN|TABLE|VALUE|OPTIONAL|REFERENCE|TO|USING|CHANGING|RAISING|EXCEPTION|EXCEPTIONS|RETURN|RETURNING|CAST|ABSTRACT|FINAL|STATIC|INSTANCE|CONSTRUCTOR|DESTRUCTOR|IMPORTING|EXPORTING|RECEIVING|EXCEPTION-TABLE|PREFERRED-PARAMETER)\b/,
  'boolean': /\b(?:ABAP_TRUE|ABAP_FALSE)\b/,
  'operator': /[=<>:+\-*/%&|^!~?@#]/,
  'number': /\b\d+\b/,
  // More patterns can be added here
};
