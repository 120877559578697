import React from "react";
import { Box, Image, Badge, Text, VStack, HStack } from "@chakra-ui/react";
import { FaGithub, FaYoutube } from "react-icons/fa";
import { VscGitCommit } from "react-icons/vsc";

const CARD_HEIGHT = "400px"; // Constant outside the component

const Card = ({
  shortTitle,
  image,
  category,
  description,
  author,
  date,
  tCode,
  tVideo,
}) => {
  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      height={CARD_HEIGHT}
    >
      <Image
        src={image}
        alt={shortTitle}
        width="100%"
        height="60%"
        objectFit="cover"
      />

      <Box p="4" height="40%">
        <VStack align="start" spacing="2">
          <HStack width="100%">
          {category.map((cat, index) => (
                <Badge key={index} borderRadius="full" px="2" colorScheme="teal">
                  {cat}
                </Badge>
              ))}

            <HStack>
              {tCode && <FaGithub />}
              {tVideo && <FaYoutube />}
            </HStack>
          </HStack>
          <HStack spacing={2}>
            {author && (
              <Text fontSize="xs" color="gray.500">
                {author}
              </Text>
            )}

            {author && date && (
              <Text fontSize="xs" color="gray.500">
                <VscGitCommit />
              </Text>
            )}

            {date && (
              <Text fontSize="xs" color="gray.400">
                {new Date(
                  new Date(date).getTime() +
                    new Date(date).getTimezoneOffset() * 60000
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Text>
            )}
          </HStack>

          <Box fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
            {shortTitle}
          </Box>
        </VStack>

        <Box>{description}</Box>
      </Box>
    </Box>
  );
};

export default Card;
