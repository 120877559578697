import emailjs from "@emailjs/browser";

const sendSubscriptionEmail = (
  email,
  userId,
  serviceId,
  subscriptionTemplateId
) => {
  if (!email || email.trim() === "") {
    return Promise.reject(new Error("Email address is empty."));
  }

  return emailjs.send(serviceId, subscriptionTemplateId, { email }, userId);
};

export { sendSubscriptionEmail };
