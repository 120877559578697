import { collection, getDocs, getFirestore } from "firebase/firestore";
import { app } from "./Firebase";
 // Ensure you have a reference to your Firestore database
const getBlogs = async () => {
    const database = getFirestore(app);
  const blogsCollectionRef = collection(database, "blogs");
  try {
    const blogsSnapshot = await getDocs(blogsCollectionRef);
    const blogsList = blogsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return blogsList;

  } catch (error) {
    console.error("Error fetching authors:", error);
  }
};

const getAuthors = async () => {
    const database = getFirestore(app);
  const authorsCollectionRef = collection(database, "authors");
  try {
    const authorsSnapshot = await getDocs(authorsCollectionRef);
    const authorsList = authorsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return authorsList;

  } catch (error) {
    console.error("Error fetching authors:", error);
  }
};

const getTutorials = async () => {
  const database = getFirestore(app);
const tutorialsCollectionRef = collection(database, "tutorials");
try {
  const tutorialsSnapshot = await getDocs(tutorialsCollectionRef);
  const tutorialsList = tutorialsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return tutorialsList;

} catch (error) {
  console.error("Error fetching tutorials:", error);
}
};

export { getBlogs, getAuthors, getTutorials };