import React, { useContext, useMemo, useState } from "react";
import "../../../App.css";
import {
  Badge,
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Card from "../../../utils/TutorialCard";
import { SearchIcon } from "@chakra-ui/icons";
import { DataContext } from "../../../services/DataContext";

function TutorialsListPage() {
  const [isLessThan1150] = useMediaQuery("(max-width: 1150px)");
  const columnsCount = isLessThan1150 ? 2 : 3;
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { tutorialData, authorData } = useContext(DataContext);

  const combinedData = useMemo(() => {
    return tutorialData.map((tutorial) => {
      const author = authorData.find((a) => a.id === tutorial.authorID) || {};
      return { ...tutorial, author }; // Add author data to each blog
    });
  }, [tutorialData, authorData]);

  const handleCardClick = (path) => {
    navigate(path);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const [selectedCategory, setSelectedCategory] = useState("All");

  const categories = useMemo(() => {
    // Check if combinedData is an array and has elements
    if (!Array.isArray(combinedData) || combinedData.length === 0) {
      // Return an empty array if combinedData is not an array or is empty
      return [];
    }
  
    // Extract categories from each tutorial entry and flatten them into a single array
    const allCategories = combinedData.flatMap(entry => entry.category || []);
  
    // Create a set of unique categories
    const uniqueCategoriesSet = new Set(allCategories);
  
    // Convert the set to an array and sort it
    return ["All", ...Array.from(uniqueCategoriesSet).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))];
  }, [combinedData]);

  const filteredData = useMemo(() => {
    return combinedData.filter((tutorial) => {
      // Check if the current tutorial matches the selected category
      const categoryMatch =
      selectedCategory === "All" || 
      (Array.isArray(tutorial.category) && tutorial.category.includes(selectedCategory));
    // Check if the current tutorial matches the search query
      const searchMatch =
        searchQuery === "" ||
        tutorial.shortTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tutorial.description.toLowerCase().includes(searchQuery.toLowerCase());
      // Return the tutorial if it matches both the category and the search query
      return categoryMatch && searchMatch;
    });
  }, [searchQuery, selectedCategory, combinedData]);

  const sortedFilteredData = useMemo(() => {
    return filteredData
      .slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [filteredData]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        p={{ base: "4", md: "4", lg: "4" }}
        minH="95vh"
        maxWidth="1200px"
        width="100%"
        margin="auto"
      >
        <Heading as="h1" size="xl" mb="8" textAlign="center">
          Tutorials
        </Heading>
        <Flex justify="center" mb="4">
          <InputGroup
            maxWidth={isFocused ? "350px" : "250px"}
            width="100%"
            transition="max-width 0.3s ease-in-out"
          >
            <InputLeftElement pointerEvents="none">
              <SearchIcon color={isFocused ? "teal.500" : "gray.400"} />
            </InputLeftElement>
            <Input
              placeholder="Search"
              borderColor="gray.300"
              _hover={{ borderColor: "gray.400" }}
              _focus={{
                outline: "none",
                borderColor: "teal.500",
                boxShadow: "0 0 0 1px teal.500",
              }}
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </InputGroup>
        </Flex>
        <Flex wrap="wrap" justify="center" mb="4" maxW="600px" mx="auto">
          {categories.map((category) => (
            <Badge
              key={category}
              onClick={() => setSelectedCategory(category)}
              cursor="pointer"
              colorScheme={selectedCategory === category ? "teal" : "gray"}
              m="1"
              px="3"
              py="1"
              fontSize="sm"
              borderRadius="md"
            >
              {category}
            </Badge>
          ))}
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: columnsCount }}
          spacing="40px"
          justifyItems="center"
          alignItems="center"
        >
          {sortedFilteredData.map((card, index) => (
            <Box
              onClick={() => handleCardClick(card.id)}
              cursor="pointer"
              key={index}
              _hover={{
                transform: "scale(1.05)",
                shadow: "md",
                textDecoration: "none",
              }}
              transition="transform 0.2s, box-shadow 0.2s"
            >
              <Card
                shortTitle={card.shortTitle}
                image={card.image}
                category={card.category}
                description={card.description}
                author={card.author.author}
                date={card.date}
                tCode={card.tCode}
                tVideo={card.tVideo}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}

export default TutorialsListPage;
