import { Box, IconButton } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { css, keyframes, useTheme } from "@emotion/react";
import { ChevronUpIcon, HamburgerIcon } from "@chakra-ui/icons";

const slideIn = keyframes`
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideOut = keyframes`
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(100%); opacity: 0; }
`;

const BackToTopButton = () => {
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollingUp = currentScrollY > lastScrollY;
      const nearTop = currentScrollY < 50;

      // Show button when scrolling up and more than 50px away from the top
      if (scrollingUp && !nearTop) {
        setShowButton(true);
      }
      // Hide button when scrolling down and within 50px of the top
      else if (!scrollingUp && nearTop) {
        setShowButton(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const buttonStyle = css`
    animation: ${showButton ? slideIn : slideOut} 0.5s forwards;
    position: fixed;
    bottom: 22px;
    right: 20px;
    z-index: 10;
  `;

  return (
    showButton && (
      <Box css={buttonStyle} >
        <IconButton
          icon={<ChevronUpIcon />}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          borderRadius="full"
          boxSize="40px" // Set the size of the button
          colorScheme="teal" 
        />
      </Box>
    )
  );
};

export default BackToTopButton;
