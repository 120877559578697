import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";

function PrivacyPolicyModal({ isOpen, onClose }) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxH="80vh">
            {/* Introduction */}
            <Text mb={4}>
              This Privacy Policy outlines our commitment to protecting the
              privacy of individuals who visit our website and who make use of
              the online facilities.
            </Text>

            {/* Information Collection */}
            <Text mb={4}>
              <strong>The Information We Collect:</strong> The only personal
              information we collect is the email address provided voluntarily
              when you subscribe to our services or contact us through our
              website.
            </Text>

            {/* How We Use Your Information */}
            <Text mb={4}>
              <strong>How We Use Your Information:</strong> Your email address
              is used to respond to your inquiries, to send you newsletters or
              updates about our services, and to inform you about new content
              that may interest you.
            </Text>

            {/* Data Protection */}
            <Text mb={4}>
              <strong>Data Protection:</strong> We take the protection of your
              personal information seriously and use industry-standard security
              measures to prevent the loss, misuse, or alteration of the
              information under our control.
            </Text>

            {/* Your Rights */}
            <Text mb={4}>
              <strong>Your Rights:</strong> You have the right to access and
              correct, or delete your personal information at any time.
            </Text>

            {/* Policy Changes */}
            <Text mb={4}>
              <strong>Policy Changes:</strong> Any changes to our Privacy Policy
              will be communicated through our website. Your continued use of
              our site after such changes will constitute acknowledgment and
              agreement to the modified policy.
            </Text>

            {/* Contact Information */}
            <Text mb={4}>
              <strong>Contact Information:</strong> If you have any questions or
              concerns about our Privacy Policy or our data practices, please
              contact us at{" "}
              <Link
                href="mailto:info@spatiumtech.com"
                color="teal.500"
                isExternal
              >
                info@spatiumtech.com
              </Link>
              .
            </Text>

            {/* Effective Date */}
            <Text mb={4}>
              This privacy policy is effective as of Jan 08, 2024.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button bg="#18D9D9" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PrivacyPolicyModal;
