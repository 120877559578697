import React from "react";
import {
  Flex,
  IconButton,
  Button,
  Text,
  Link,
  Box,
  useColorModeValue,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { FaTwitter, FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import ContactUsModal from "../modals/ContactUsModal";
import SubscribeModal from "../modals/SubscribeModal";
import PrivacyPolicyModal from "../modals/PrivacyPolicyModal";
import TermsOfUseModal from "../modals/TermsOfUseModal";

function Footer() {
  const footerTextColor = useColorModeValue(
    "footer.light.color",
    "footer.dark.color"
  );

  const {
    isOpen: isContactUsModalOpen,
    onOpen: onContactUsModalOpen,
    onClose: onContactUsModalClose,
  } = useDisclosure();

  const {
    isOpen: isSubscribeModalOpen,
    onOpen: onSubscribeModalOpen,
    onClose: onSubscribeModalClose,
  } = useDisclosure();

  const {
    isOpen: isPrivacyPolicyModalOpen,
    onOpen: onPrivacyPolicyModalOpen,
    onClose: onPrivacyPolicyModalClose,
  } = useDisclosure();

  const {
    isOpen: isTermsOfUseModalOpen,
    onOpen: onTermsOfUseModalOpen,
    onClose: onTermsOfUseModalClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        as="footer"
        direction="column"
        pt="2rem"
        pb="2rem"
        alignItems="center"
        justifyContent="center"
        bg="transparent"
        maxW="1200px"
        mx="auto"
      >
        <Flex mb="4" textAlign="center" alignItems="center">
          <Text fontSize="lg" mb="2" mr="2">
            © Spatium Tech
          </Text>
          <Image src="/images/1-icon.png" alt="Icon" boxSize="35px" mb="2" />
        </Flex>

        <Flex
          width="80%"
          justifyContent="space-between"
          alignItems="center"
          mb="4"
        >
          <Flex>
            <IconButton
              as="a"
              href="https://www.youtube.com/@spatiumtech1731"
              aria-label="Youtube"
              icon={<FaYoutube />}
              size="sm"
              variant="ghost"
              m="1"
              target="_blank"
              rel="noopener noreferrer"
            />
            <IconButton
              as="a"
              href="https://instagram.com/spatiumtech"
              aria-label="Instagram"
              icon={<FaInstagram />}
              size="sm"
              variant="ghost"
              m="1"
              target="_blank"
              rel="noopener noreferrer"
            />
          </Flex>

          <Flex>
            <Button backgroundColor="#18D9D9" size="sm" mr="2" onClick={onContactUsModalOpen}>
              Contact Us
            </Button>
            <Button backgroundColor="#18D9D9" size="sm" onClick={onSubscribeModalOpen}>
              Subscribe
            </Button>
          </Flex>
        </Flex>

        <Flex fontSize="sm" justifyContent="center" alignItems="center">
          <Link color={footerTextColor} mr="2" onClick={onTermsOfUseModalOpen}>
            Terms of Use
          </Link>
          <Text color={footerTextColor} mx="2">
            |
          </Text>
          <Link cursor="pointer" color={footerTextColor} ml="2" onClick={onPrivacyPolicyModalOpen}>
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
      <ContactUsModal isOpen={isContactUsModalOpen} onClose={onContactUsModalClose} />
      <SubscribeModal isOpen={isSubscribeModalOpen} onClose={onSubscribeModalClose} />
      <PrivacyPolicyModal isOpen={isPrivacyPolicyModalOpen} onClose={onPrivacyPolicyModalClose} />
      <TermsOfUseModal isOpen={isTermsOfUseModalOpen} onClose={onTermsOfUseModalClose} />
    </>
  );
}

export default Footer;
