import React, { useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Link as ChakraLink,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  VStack,
  Box,
  HStack,
  Image,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import { css, keyframes } from "@emotion/react";
import { SunIcon, MoonIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation, Link } from "react-router-dom";

const slideIn = keyframes`
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideOut = keyframes`
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(100%); opacity: 0; }
`;

const NavbarLink = ({ name, href, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === href;

  const hoverBg = useColorModeValue(
    "hoverLink.light.backgroundColor",
    "hoverLink.dark.backgroundColor"
  );
  const hoverColor = useColorModeValue(
    "hoverLink.light.color",
    "hoverLink.dark.color"
  );

  const activeBg = isActive ? hoverBg : "transparent";
  const activeColor = isActive ? hoverColor : undefined;

  const handleClick = () => {
    navigate(href);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <ChakraLink
      onClick={handleClick}
      px={2}
      py={1}
      rounded={"md"}
      bg={activeBg}
      color={activeColor}
      _hover={{
        textDecoration: "none",
        backgroundColor: hoverBg,
        color: hoverColor,
      }}
      style={{ zIndex: 10 }}
    >
      {name}
    </ChakraLink>
  );
};

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navbarLogo = "images/4-icon.png";
  const [logoError, setLogoError] = useState(false);

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollingUp = currentScrollY > lastScrollY;
      const nearTop = currentScrollY < 50;

      // Show button when scrolling up and more than 50px away from the top
      if (scrollingUp && !nearTop) {
        setShowButton(true);
      }
      // Hide button when scrolling down and within 50px of the top
      else if (!scrollingUp && nearTop) {
        setShowButton(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const buttonStyle = css`
    animation: ${showButton ? slideIn : slideOut} 0.5s forwards;
    position: fixed;
    top: 35px;
    right: 20px;
    z-index: 10;
  `;

  const navbarBgColor = useColorModeValue(
    "navbar.light.backgroundColor",
    "navbar.dark.backgroundColor"
  );
  const navbarTextColor = useColorModeValue(
    "navbar.light.color",
    "navbar.dark.color"
  );
  return (
    <>
      {!showButton && (
        <Flex
          as="nav"
          justifyContent={{ base: "space-between", md: "flex-start" }}
          align="center"
          padding="4"
          position="fixed"
          top={0}
          left="50%" // Position the navbar starting from the center of the page
          transform="translateX(-50%)" // Shift the navbar back to ensure it's centered
          width="80%" // Set the navbar width to 80% of the viewport width
          maxWidth="1200px" // Ensure the navbar does not exceed the viewport width
          transition="0.5s ease-out"
          zIndex={1000}
        >
          <Box>
            <Link to="/">
              <Image
                src={navbarLogo}
                onError={(e) => {
                  console.log("Failed to load logo image:", e.target.src);
                  setLogoError(true);
                }}
                alt="Spatium Tech Logo"
              />
            </Link>
          </Box>
          <Spacer />
          <Box flex="1">
            <Flex
              flex={{ base: 1, md: 1, lg: 3 }}
              justify="left"
              align="left"
              minWidth="0"
            >
              <HStack
                spacing={4}
                display={{ base: "none", md: "flex" }}
                align="center"
                flex={1}
                justifyContent="center"
              >
                <NavbarLink name="Home" href="/" />
                <NavbarLink name="Learning" href="/learning" />
                <NavbarLink name="Blog" href="/blog" />
              </HStack>
            </Flex>
          </Box>
          <Spacer />
          <IconButton
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            isRound
            size="md"
            aria-label="Toggle Color Mode"
            display={{ base: "none", md: "flex" }}
          />
        </Flex>
      )}
      <Box css={buttonStyle}>
        <IconButton
          icon={<HamburgerIcon />}
          onClick={onOpen}
          aria-label="Open menu"
          display={{ base: "none", md: "inline-flex" }}
        />
      </Box>
      <IconButton
        icon={<HamburgerIcon />}
        aria-label="Open menu"
        position="fixed"
        top="30px"
        right="20px"
        zIndex={10}
        onClick={onOpen}
        display={{ base: "inline-flex", md: "none" }}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Flex direction="column" h="full">
              <VStack mt={10} spacing={4} align="center">
                <NavbarLink name="Home" href="/" onClose={onClose} />
                <NavbarLink
                  name="Learning"
                  href="/learning"
                  onClose={onClose}
                />
                <NavbarLink name="Blog" href="/blog" onClose={onClose} />
              </VStack>
              <Spacer />
              <Divider my="20px" />
              <Box
                pt="20px"
                pb="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
                  onClick={toggleColorMode}
                  isRound
                  size="md"
                  aria-label="Toggle Color Mode"
                />
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Navbar;
