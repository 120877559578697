import React from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DataProvider } from "./services/DataContext"; // Import the DataProvider

import "./App.css";
import { theme } from "./styles/Theme";
import Navbar from "./components/sections/Navbar";
import Footer from "./components/sections/Footer";
import Home from "./components/pages/Home";
import BackToTopButton from "./components/buttons/BackToTopButton";
import TutorialsListPage from "./components/pages/tutorials/TutorialsListPage";
import TutorialPage from "./components/pages/tutorials/TutorialPage";
import BlogListPage from "./components/pages/blog/BlogListPage";
import BlogPage from "./components/pages/blog/BlogPage";

function App() {
  const navbarHeight = '100px';
  return (
      <ChakraProvider theme={theme}>
        <Router>
          <DataProvider>
            <Navbar />
            <Box pt={navbarHeight}> {/* Apply top padding here */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/learning" element={<TutorialsListPage />} />
              <Route path="/learning/:path" element={<TutorialPage />} />
              <Route path="/blog" element={<BlogListPage />} />
              <Route path="/blog/:path" element={<BlogPage />} />
            </Routes>
            </Box>
            <BackToTopButton />
            <Footer />
          </DataProvider>
        </Router>
      </ChakraProvider>
  );
}

export default App;
