import { getFirestore, doc, setDoc } from "firebase/firestore";
import { app } from "./Firebase";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";

const database = getFirestore(app);

const saveUserEmail = async (email) => {
  if (!email) return;
  const date = new Date().toISOString().split("T")[0];
  const subscriberID = uuidv4();
  const subscriberData = {
    email,
    date,
  };

  try {
    const subscriberRef = doc(database, "subscribers", subscriberID);
    await setDoc(subscriberRef, subscriberData);
    console.log("Subscriber created: ", subscriberData);
  } catch (error) {
    console.error("Error saving user email", error);
    throw error;
  }
};

const saveContactUsInfo = async (name, email, message) => {
  const date = new Date().toISOString().split("T")[0];
  const contactID = uuidv4();
  const contactData = {
    name,
    email,
    message,
    date,
  };
  debugger;
  try {
    const contactRef = doc(database, "contactUs", contactID);
    await setDoc(contactRef, contactData);
    console.log("Contact info saved: ", contactData);
  } catch (error) {
    console.error("Error saving contact info", error);
    throw error;
  }
};

export { saveUserEmail, saveContactUsInfo };
