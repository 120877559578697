import React, { useState, useRef } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ModalOverlay,
  Flex,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { contactUsEmail } from "../../services/ContactUsEmail";
import { saveContactUsInfo } from "../../services/SaveUserEmail";

const initialFormValues = { name: "", email: "", message: "" };

function ContactUsModal({ isOpen, onClose }) {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const notify = useToast();
  const [formErrors, setFormErrors] = useState({});

  const clearFormFields = () => {
    setFormValues(initialFormValues);
    setFormErrors({});
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.name) {
      errors.name = "Name is required";
    }
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formValues.email)) {
      errors.email = "Email is not valid";
    }
    if (!formValues.message) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleContact = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const subscriptionTemplateId = process.env.REACT_APP_EMAILJS_CONTACT_TEMP;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;

    const errors = validateForm();
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }
    setLoading(true);

    const form = formRef.current;
    const name = form["name"].value;
    const email = form["email"].value;
    const message = form["message"].value;

    contactUsEmail(
      serviceId,
      subscriptionTemplateId,
      formRef.current,
      userId
    ).then(
      async (result) => {
        setLoading(false);
        notify({
          title: "Email Sent",
          description: "Your message has been successfully sent.",
          status: "success",
        });
        try {
          await saveContactUsInfo(name, email, message);
          onClose();
          clearFormFields();
        } catch (saveError) {
          console.error("Failed to save contact info", saveError);
          // Handle saving error (optional)
        }
        onClose();
        clearFormFields();
      },
      (error) => {
        setLoading(false);
        debugger;
        notify({
          title: "Email Sending Failed",
          description: error.message || "Failed to send the email.",
          status: "error",
        });
      }
    );
  };

  const logo = "/images/1-icon.png";

  return (
    <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
      <ModalContent>
        <ModalHeader>
          <Flex direction="column" alignItems="center">
            <Image src={logo} boxSize="60px" alt="Spatium Tech Logo" mb={2} />
            <Text>Contact Us</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form ref={formRef} onSubmit={handleContact}>
            <FormControl isInvalid={formErrors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="name"
                placeholder="Your name"
                onChange={handleChange}
              />
              {formErrors.name && (
                <Text color="red.500">{formErrors.name}</Text>
              )}
            </FormControl>
            <FormControl isInvalid={formErrors.email}>
              <FormLabel>Email Address</FormLabel>
              <Input
                type="email"
                name="email"
                placeholder="Your email"
                onChange={handleChange}
              />
              {formErrors.email && (
                <Text color="red.500">{formErrors.email}</Text>
              )}
            </FormControl>
            <FormControl isInvalid={formErrors.message}>
              <FormLabel>Message</FormLabel>
              <Textarea
                type="text"
                name="message"
                placeholder="Your message"
                size="lg"
                rows={5}
                onChange={handleChange}
              />
              {formErrors.message && (
                <Text color="red.500">{formErrors.message}</Text>
              )}
            </FormControl>
            <ModalFooter>
              <Button
                disabled={loading}
                isLoading={loading}
                bg="#18D9D9"
                mr={3}
                type="submit"
              >
                Send
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ContactUsModal;
