import Prism from 'prismjs';

Prism.languages.shell = {
    'comment': /<#[\s\S]*?#>|#.*$/,
    'string': {
      pattern: /"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'/,
      greedy: true
    },
    'keyword': /\b(?:Begin|Break|Catch|Continue|Data|Do|Dynamicparam|Else|Elseif|End|Exit|Filter|Finally|For|ForEach|Function|If|In|Param|Process|Return|Switch|Throw|Trap|Try|Until|While)\b/,
    'boolean': /\b(?:\$true|\$false)\b/,
    'cmdlet': /(?:Get|Set|Start|Stop|Out|New)-[A-Za-z0-9]+/,
    'alias': /(?:gci|gcm|gsv|gps|gpv|gm|sc|rcjb|sajb|spjb)\b/,
    'variable': /\$\w+/,
    'operator': /-eq|-ne|-lt|-le|-gt|-ge|-like|-notlike|-match|-notmatch|-contains|-notcontains|-replace/,
    'number': /\b\d+\b/,
    // More patterns can be added here
  };
  